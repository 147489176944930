import style from './Scores.module.scss'

const Scores = () => {
  return (
    <div className={style.container}>
      <div className={style.header}>
        <h1 className={style.title}>Sistema de puntuación</h1>
        <ul className={style.list}>
          <li>Todos los jugadores no convocados o que se queden en la banca tienen <b>0 puntos</b>.</li>
          <li>Los puntos que genere el <b>capitán</b> del equipo seleccionado <b>suma doble puntaje (un x2)</b>.</li>
          <li>Los partidos de la jornada que sean <b>cancelados o pospuestos</b> no será responsabilidad de <b>AstroGool</b> y dichos jugadores seleccionados darán <b>0 puntos</b>.</li>
          <li>Los puntajes serán publicados en su totalidad al <b>final de cada jornada</b>.</li>
        </ul>
      </div>
      <div className={style.content}>
        <div className={style.section}>
          <h2 className={style.subtitle}>De inicio</h2>
          <ul className={style.list}>
            <li>Por alinear de inicio <b>+3</b></li>
            <li>Por alinear de cambio <b>+1</b></li>
            <li>Sale del partido de cambio <b>-1</b></li>
          </ul>
        </div>
        <div className={style.section}>
          <h2 className={style.subtitle}>Goles anotados</h2>
          <ul className={style.list}>
            <li>Delantero <b>+2</b></li>
            <li>Medio <b>+3</b></li>
            <li>Defensa <b>+5</b></li>
            <li>Portero <b>+9</b></li>
            {/* <li>Asistencias a gol <b>+1</b></li> */}
            {/* <li>3 o más goles del equipo (aplica sólo a delantero y medio) <b>+1</b></li>
            <li>0 goles anotados del equipo (aplica sólo a delantero y medio) <b>-1</b></li> */}
            <li>Jugador con 2 o más goles en el partido <b>+2</b></li>
            <li>Autogol <b>-3</b></li>
          </ul>
        </div>
        {/* <div className={style.section}>
          <h2 className={style.subtitle}>Goles recibidos</h2>
          <ul className={style.list}>
            <li>3 o más goles recibidos (aplica a defensa y portero) <b>-2</b></li>
            <li>0 goles recibidos (aplica a defensa y portero) <b>+2</b></li>
            <li>Penal atajado (aplica portero) <b>+3</b></li>
          </ul>
        </div> */}
        <div className={style.section}>
          <h2 className={style.subtitle}>Tarjetas</h2>
          <ul className={style.list}>
            <li>Tarjeta amarilla <b>-1</b></li>
            <li>Tarjeta roja (2 amarillas) <b>-3</b></li>
            <li>Tarjeta roja directa <b>-5</b></li>
          </ul>
        </div>
        <div className={style.section}>
          <h2 className={style.subtitle}>Resultados del partido</h2>
          <div className={style.table}>
            <div className={style.col}>
              <h3>Local</h3>
              <ul className={style.list}>
                <li>Victoria <b>+1</b></li>
                <li>Empate <b>0</b></li>
                <li>Derrota <b>-3</b></li>
              </ul>
            </div>
            <div className={style.col}>
              <h3>Visitante</h3>
              <ul className={style.list}>
                <li>Victoria <b>+3</b></li>
                <li>Empate <b>+1</b></li>
                <li>Derrota <b>-1</b></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Scores
